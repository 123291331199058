import React from 'react';

import './App.css';
import Navbar from './Components/nav_bar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './Pages/privacy_policy';
import AccountData from './Pages/account_data';
import Login from './Pages/login';
import Home from './Pages/home';
import PrivateRoute from './Components/private_route';

function App() {
  return (
    <Router>
      <div>
        <Navbar />

        {/* Usa el componente <Routes> para envolver tus rutas */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
          {/* Protected Routes*/}
          <Route element={<PrivateRoute />}>
            <Route path="/account-data" element={<AccountData />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase';
import { collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import AlertModal from './alert_modal';

const AccountData = () => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const q = query(collection(firestore, 'users'), where('uid', '==', user.uid));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            setUserData(querySnapshot.docs[0].data());
          } else {
            console.log('No user data found.');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  const handleDeleteAccount = async () => {
    try {
      if (user) {
        const q = query(collection(firestore, 'users'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const userDoc = querySnapshot.docs[0].ref;
        await deleteDoc(userDoc);

        await user.delete();

        await signOut(auth);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error al eliminar la cuenta', error);
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <div className="text-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h1 className="text-lg font-medium text-gray-700">Cargando datos...</h1>
        </div>
      </div>
    );
  }

  if (!user) {
    return <p>No estás autenticado.</p>;
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      {userData ? (
        <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Datos de la Cuenta</h1>
          <div className="flex items-center mb-6">
            <img
              src={userData?.photoURL || 'https://via.placeholder.com/150'}
              alt="Perfil"
              className="h-16 w-16 rounded-full object-cover mr-4"
            />
            <div>
              <h2 className="text-xl font-semibold text-gray-800">{userData?.displayName || 'Nombre de Usuario'}</h2>
              <p className="text-gray-600">{userData?.email || 'Email de Usuario'}</p>
              <p className="text-gray-500 text-sm">UID: {user.uid}</p>
            </div>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="w-full rounded-md bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Eliminar cuenta y datos asociados
          </button>
        </div>
      ) : (
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No tienes datos registrados</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Descarga nuestra aplicación desde la PlayStore o la AppStore</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Volver al inicio
            </Link>
            <a href="#" className="text-sm font-semibold text-gray-900">
              Descarga la aplicación
            </a>
          </div>
        </div>
      )}

      <AlertModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDeleteAccount}
      />
    </div>
  );
};

export default AccountData;

import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto p-8 rounded-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Política de Privacidad</h1>
        <p className="text-gray-700 mb-4">
          En nuestra aplicación, respetamos y protegemos su privacidad. Esta Política de Privacidad explica cómo recogemos, usamos, compartimos y protegemos su información personal cuando utiliza nuestra aplicación.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Información que Recogemos</h2>
        <p className="text-gray-700 mb-4">
          Recogemos información personal que usted nos proporciona directamente, como su correo electrónico y el nombre de usuario cuando se registra o inicia sesión. También recogemos información de perfil y de interacción dentro de la aplicación, como la imagen de perfil y los likes en los blogs.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Uso de la Información</h2>
        <p className="text-gray-700 mb-4">
          Usamos la información personal para autenticar su acceso a la aplicación, proporcionar funcionalidades y mejorar su experiencia en la aplicación. También utilizamos esta información para personalizar su perfil y ofrecerle contenido relevante.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Compartición de la Información</h2>
        <p className="text-gray-700 mb-4">
          No compartimos su información personal con terceros sin su consentimiento, excepto cuando sea necesario para cumplir con las leyes aplicables o para proteger nuestros derechos y la seguridad de otros usuarios.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Seguridad</h2>
        <p className="text-gray-700 mb-4">
          Implementamos medidas de seguridad razonables para proteger su información personal contra acceso no autorizado, alteración, divulgación o destrucción.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Cambios en esta Política</h2>
        <p className="text-gray-700 mb-4">
          Podemos actualizar esta Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio significativo en la forma en que tratamos su información personal publicando la nueva política en esta página.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4 mb-2 py-6">Derechos de los Usuarios</h2>
        <h3 className="text-lg font-semibold text-gray-800 mt-2 mb-2">Eliminación de Datos Personales</h3>
        <p className="text-gray-700 mb-4">
          Usted tiene el derecho de solicitar la eliminación de su información personal en cualquier momento. Si desea que eliminemos sus datos personales de nuestros registros, por favor, póngase en contacto con nosotros a través de <a href="mailto:mentesmillonariasdehoy@gmail.com" className="text-indigo-600 hover:text-indigo-800">mentesmillonariasdehoy@gmail.com</a> o acceda al apartado correspondiente en esta misma pagina.
        </p>
        <h3 className="text-lg font-semibold text-gray-800 mt-2 mb-2">Cómo Solicitar la Eliminación de Datos</h3>
        <p className="text-gray-700 mb-4">
          Para solicitar la eliminación de sus datos personales, puede:
        </p>
        <ol className="list-decimal list-inside text-gray-700 mb-4">
          <li>
            <strong>Enviar un Correo Electrónico:</strong> Envíe un correo electrónico a <a href="mailto:mentesmillonariasdehoy@gmail.com" className="text-indigo-600 hover:text-indigo-800">mentesmillonariasdehoy@gmail.com</a> con el asunto "Solicitud de Eliminación de Datos". Incluya en el correo la información necesaria para verificar su identidad (Esto puede incluir correo y UID de usuario).
          </li>
          <li>
            <strong>Formulario en la Pagina Web:</strong> Inicie sesión y acceda a la sección "Datos de la cuenta" desde su perfil, debera seguir las instrucciones para solicitar la eliminación de su cuenta y datos asociados.
          </li>
        </ol>
        <h3 className="text-lg font-semibold text-gray-800 mt-2 mb-2">Proceso de Eliminación</h3>
        <p className="text-gray-700 mb-4">
          Una vez recibida su solicitud, procesaremos la eliminación de sus datos personales dentro de un plazo razonable y de acuerdo con nuestras políticas internas y requisitos legales. Tenga en cuenta que algunos datos pueden ser retenidos por motivos legales o de auditoría.
        </p>
        <h2 className="text-xl font-semibold text-gray-800 mt-4 mb-2">Contacto</h2>
        <p className="text-gray-700 mb-4">
          Si tiene alguna pregunta sobre esta Política de Privacidad, puede ponerse en contacto con nosotros a través de la aplicación o enviarnos un correo electrónico a <a href="mailto:mentesmillonariasdehoy@gmail.com" className="text-indigo-600 hover:text-indigo-800">mentesmillonariasdehoy@gmail.com</a>.
        </p>
      </div>
    </div>

  );
}

export default PrivacyPolicy;
